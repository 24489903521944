import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Form, Row } from 'react-bootstrap';
import { Header } from './components/Header';
import { AddTaskForm } from './components/AddTaskForm';
import { api } from './lib/api';
import { WholeSpinner } from './components/WholeSpinner';
import './TodoListMainPage.scss'

type TodoListMainPageProps = {
  signOut: any;
  userName: string | undefined;
}

type TaskItem = {
  id: number;
  task_name: string;
  deadline_date: string | null;
  done: boolean
}

const TodoListMainPage = React.memo(({ signOut, userName }: TodoListMainPageProps) => {
  const undoneList = useRef<TaskItem[]>([]);
  const doneList = useRef<TaskItem[]>([]);
  const [loading, setLoaging] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    const initialize = () => {
      undoneList.current = [];
      doneList.current = [];
      setLoaging(true);
    }
    const getTasks = (async () => {
      console.time("getTasks");
      const undone = await api.get("task/?done=false");
      if (undone?.data) {
        undoneList.current = undone.data as TaskItem[];
      }
      const done = await api.get("task/?done=true");
      if (done?.data) {
        doneList.current = done.data as TaskItem[];
      }
      setLoaging(false);
      console.timeEnd("getTasks");
    });
    initialize();
    getTasks();
  }, [reload]);

  /**
   * 新規にタスクを追加したときに実行する関数
   */
  const onAddTask = useCallback(() => {
    setReload((prev) => !prev)
  }, [])

  /**
   * TODOリストの要素にチェックを入れたときに実行する関数
   * @param {number} taskId DB上で管理しているTODO項目のID
   * @param {string} taskName TODO項目名（ログ出力用）
   * @param {boolean} done 未完了→完了のときtrue
   */
  const onChangeItemChecked = useCallback(async (taskId: number, taskName: string, done: boolean) => {
    console.log(`${done ? "[DONE]" : "[UNDONE]"}`, taskId, taskName)
    try {
      console.time("patchTask")
      await api.patch(`task/${taskId}/`, { done: done })
    }
    catch {
      alert("失敗しました。リトライしてください")
    }
    finally {
      console.timeEnd("patchTask")
    }
  }, [])

  return (
    <>
      <Header signOut={signOut} userName={userName} />
      <Container className='main-contents'>
        <Row>
          <AddTaskForm onSend={onAddTask} />
        </Row>
        <hr />
        {
          loading
            ?
            <Row>
              <WholeSpinner />
            </Row>
            :
            <>
              <Row>
                <Form>
                  {
                    undoneList.current.map((item) => (
                      <Form.Check
                        id={`undone-${item.id}`}
                        label={`${item.task_name} [${item.deadline_date}]`}
                        onChange={(e) => { onChangeItemChecked(item.id, item.task_name, e.target.checked) }}
                      />
                    ))
                  }
                </Form>
              </Row>
              <Row>
                <Form>
                  {
                    doneList.current.map((item) => (
                      <Form.Check
                        defaultChecked
                        id={`done-${item.id}`}
                        label={`${item.task_name} [${item.deadline_date}]`}
                        onChange={(e) => { onChangeItemChecked(item.id, item.task_name, e.target.checked) }}
                      />
                    ))
                  }
                </Form>
              </Row>
            </>
        }
      </Container>
    </>
  );
})

export { TodoListMainPage }
