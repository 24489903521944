import axios from "axios";

const apiUrl = (): string => {
  let url: string
  switch (process.env.NODE_ENV) {
    case "production":
      url = String(process.env.REACT_APP_PROD_API_URL)
      break
    default:
      url = String(process.env.REACT_APP_DEV_API_URL)
      break
  }
  return url
}

const api = axios.create({
  baseURL: apiUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 2000,
  timeoutErrorMessage: "API failed (Timed out)"
})

export { api }
