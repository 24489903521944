import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { AmplifyProvider } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

Amplify.configure({
  Auth: {
    region: "us-west-2",
    userPoolId: "us-west-2_sia6OlupW",
    userPoolWebClientId: "10brdn0l5be5768kdolevsqshn"
  }
})

ReactDOM.render(
  <React.StrictMode>
    <AmplifyProvider>
      <App />
    </AmplifyProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
