import React from "react"
import { Spinner } from "react-bootstrap"

const WholeSpinner = React.memo(() => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spinner animation="border">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  )
})

export { WholeSpinner }
