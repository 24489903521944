import "./Header.scss"
import React, { useCallback, useState } from "react"
import { Navbar, Container } from "react-bootstrap"
import { MdLogout } from "react-icons/md"
import { SignoutConfirmModal } from "./SignoutConfirmModal"

type HeaderProps = {
  signOut: any;
  userName: string | undefined;
}

const Header = React.memo(({ signOut, userName }: HeaderProps) => {
  const [showSignoutConfirmModal, setShowSignoutConfirmModal] = useState<boolean>(false)

  const onClickSignOutButton = useCallback(() => {
    setShowSignoutConfirmModal(true)
  }, [])

  const onHideSignoutConfirmModal = useCallback(() => {
    setShowSignoutConfirmModal(false)
  }, [])

  return (
    <>
      <Navbar bg="light">
        <Container>
          <Navbar.Brand href="#home">
            <img
              src="./favicon.ico"
              className="d-inline-block align-top logo"
              alt="Azume Todo Logo"
            />
            <span className="brand-name">Azuma Todo</span>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              {
                userName &&
                <b>{userName}</b>
              }
              <MdLogout onClick={onClickSignOutButton} style={{ cursor: "pointer", margin: "0.5rem" }} size={"1.8rem"} />
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <SignoutConfirmModal
        signOut={signOut}
        showModal={showSignoutConfirmModal}
        onHideModal={onHideSignoutConfirmModal}
      />
    </>
  )
})

export { Header }
