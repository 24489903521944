import { Authenticator } from '@aws-amplify/ui-react'
import { TodoListMainPage } from "./TodoListMainPage";

function App() {
  return (
    <div className="App">
      <Authenticator>
        {
          ({ signOut, user }) => (
            <TodoListMainPage signOut={signOut} userName={user.username} />
          )
        }
      </Authenticator>
    </div>
  );
}

export default App;
